import styled from "styled-components";

export const Wrapper = styled.div`
color: white;

table{
    text-align: center;
thead{
tr{
th {
border: 50px solid transparent !important;
}
}
}
tbody {
tr{
td{
border: 5px solid transparent;

button {
color: white;
background-color: red;
border: 1px solid red;
cursor: pointer;
padding: 7px 20px;
border-radius: 10px;
}
}
}
}
}
`