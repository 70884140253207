import styled from "styled-components";

export const WrapperTable = styled.div`
table {
color: white;

thead{
tr{
th{
border: 50px solid transparent;
}
}
}
}
`