import styled from "styled-components";


export const Wrapper = styled.div`


`
export const WrapperBottom = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
   button:nth-child(1){
    cursor: pointer;
    background-color: green;
    padding: 10px 30px;
    font-size: 20px;
    color: white;
    margin-top: 10px;
    margin-right: 20px;
    border-radius: 20px;
    } 
button:nth-child(2){
    cursor: pointer;
    background-color: red;
    padding: 10px 30px;
    font-size: 20px;
    color: white;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 20px;
    } 

`